/* General styles */
body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    background-color: #2A2A6A;
    color: #E4E1E9;
}

header {
    background-color: #2A292F;
    padding: 20px;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures space between left and right sections */
}

.left-section {
    display: flex;
    align-items: center;
}

.right-section {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 24px;
    margin-right: 10px;
}

.company-name {
    font-size: 24px;
    color: #E4E1E9;
    text-decoration: none;
}

.navbar {
    margin-left: 20px;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
    align-items: center;
}

.navbar li {
    display: inline;
}

.navbar a {
    color: #E4E1E9;
    text-decoration: none;
    font-size: 14px;
    padding: 5px 10px;
}

.auth-buttons {
    display: flex;
    gap: 10px;
    background-color: #C2C1FF; /* Background color around the buttons */
    padding: 5px 15px; /* Padding around the buttons */
    border-radius: 10px; /* Rounded corners */
}

.auth-buttons .button {
    background-color: #2A2A6A;
    color: #C2C1FF;
    border-radius: 5px;
    padding: 5px 15px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.auth-buttons .button:hover {
    background-color: #E4E1E9;
    color: #2A2A6A;
}

/* Hero section styles */
.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2A292F;
    padding: 50px 20px;
    height: 80vh;
    background-size: cover;
    overflow: hidden;
    border: 1px solid #E4E1E9;
}

.hero-content {
    width: 50%;
    text-align: left;
    margin-left: 5%;
}

.coming-soon {
    font-size: 16px;
    color: #C2C1FF;
    margin-bottom: 5px;
}

.hero h1, .hero-description {
    max-width: 480px;
}

.cta-button {
    background-color: #C2C1FF;
    color: #2A2A6A;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.hero-image {
    width: 45%;
    margin-left: 10%;
    flex-shrink: 0;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 10px;
}

.community {
    background-color: #C2C1FF;
    text-align: center;
    padding: 50px 20px;
    margin-top: 50px;
}

.community h2 {
    font-size: 40px;
    color: #2A2A6A;
}

.community p {
    font-size: 16px;
    color: #2A2A6A;
    margin: 10px 0 30px;
}

#signup-form {
    display: flex;
    justify-content: center;
    gap: 10px;
}

#signup-form input[type="email"] {
    padding: 10px;
    border: 1px solid #2A2A6A;
    border-radius: 5px;
    font-size: 14px;
}

#signup-form button {
    background-color: #2A2A6A;
    color: #C2C1FF;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
}

/* Form container styles */
.form-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #2A292F;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #E4E1E9;
}

.form-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #C2C1FF;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-container input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #E4E1E9;
    border-radius: 5px;
    font-size: 16px;
}

.form-container button {
    padding: 10px 20px;
    background-color: #C2C1FF;
    color: #2A2A6A;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.form-container button:hover {
    background-color: #a8a7ff;
}

.inspiration-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.inspiration-gallery img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.inspiration-gallery img:hover {
    transform: scale(1.05);
}

.buy-button {
    display: flex;
    align-items: center;
    background-color: #FF9900;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
}

.amazon-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.parts-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.parts-list li {
    display: flex;
    align-items: center;
    background-color: #2A292F;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    justify-content: space-between;
    border: 1px solid #E4E1E9; /* Added border for alignment */
    height: 100px; /* Ensure uniform height */
}

.parts-list img.part-image {
    width: 100px;
    height: 100px;
    object-fit: cover; /* Ensures the image fits properly in the square */
    border-radius: 5px;
}

.part-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 10px;
    padding: 0 10px; /* Adds some padding for better spacing */
}

.part-info h2 {
    flex: 2;
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Limits the width of the title */
}

.part-info p {
    flex: 1;
    margin: 0;
    font-size: 14px;
}

.part-info .price {
    flex: 1;
    margin: 0;
    font-size: 14px;
    text-align: right;
}

.part-info .rating {
    flex: 1;
    display: flex;
    align-items: center;
}

.part-info .rating span {
    color: #FF9900;
    font-size: 16px;
}

.part-info .reviews {
    color: #FFCC00; /* Changes review count color to yellow */
    margin-left: 5px;
}

.add-button {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px; /* Ensures consistent button size */
}

.add-button:hover {
    background-color: #45a049;
}

/* PartDetails styles */
.amazon-button {
    display: inline-flex;
    align-items: center;
    background-color: #FF9900;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    height: 40px; /* Ensure consistent height */
}

.amazon-logo {
    width: 100px;
    height: 40px;
    object-fit: contain;
}

.part-detail {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.part-detail .image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.part-detail .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}

/* General styles */
body, html {
    margin: 0;
    padding: 0;
    background-color: #ffffff; /* White background for testing */
    color: #E4E1E9;
    font-family: 'Open Sans', sans-serif;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-content {
    padding: 20px;
    background-color: #ffffff; /* White background for main content */
}

.part-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.part-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.part-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

/* Add other styles as necessary */
